(function(cash) {
	"use strict";

    let help = {
        delay(value) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, value);
            });
        }
    };

    window.Help = help;
})(cash);